import React from "react"
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
    {
        question: "O que é a HealthTracker?",
        answer:
            "A HealthTracker é uma plataforma digital para organizar, acompanhar e compartilhar seus dados pessoais e informações médicas com seus interessados. Somos uma plataforma que apresenta informações úteis sobre comportamentos para qualidade de vida, rotinas e boas práticas para uma vida saudável. A HealthTracker não é um médico e/ou profissional da medicina. Em nenhuma ocasião a HealthTracker deve ser considerada uma alternativa ou substituto ao seus médico."
    },
    {
        question: "Como funciona o monitoramento de saúde?",
        answer:
            "A HealthTracker utiliza vários meios para acompanhamento de saúde. Para isso, nos valemos de informações (e.g. avulsas, laudos, imagem, prontuários) fornecidas voluntariamente pelo usuário de maneira a proporcionar sugestões e comportamentos para uma vida saudável de acordo com padrões mundialmente reconhecidos. A HealthTracker não substitui exames médicos de qualquer natureza e deve ser utilizado de forma complementar para organizar a agregar suas informações médicas relevantes."
    },
    {
        question: "O que é medicina preventiva e como a plataforma me ajuda?",
        answer:
            "Medicina preventiva é uma nova forma de olhar a medicina com base em comportamentos, ações e medidas prévias a uma eventual enfermidade médica. A HealthTracker utiliza sua forma de organização e sugestões pré configuradas com base em padrões médicos para sugerir boas práticas de prevenção a doenças com base em comportamentos específicos. A HealthTracker não é um médico e, em nenhuma ocasião, se propõe a substituir a função do médico."
    },
    {
        question: "A plataforma é segura?",
        answer:
            "A HealthTracker é uma plataforma que segue todos os padrões da Lei Geral de Proteção de Dados do Brasil (Lei N, 13.709/2018) bem como boas práticas internacionais. A HealthTracker é comprometida a assegurar seus dados, sendo estes compartilhados unicamente com as pessoas determinadas pelo usuário e com parceiros que são essenciais para o funcionamento da plataforma."
    },
    {
        question: "Posso compartilhar meus dados de saúde com meu médico?",
        answer:
            "A HealthTracker é uma plataforma que permite o compartilhamento de seus dados com seu médico ou terceiro interessado a escolha do usuário. Salvo ordem judicial, a HealthTracker não irá fornecer os seus dados para quaisquer terceiros, a não ser que sejam imprescindíveis para o funcionamento da plataforma."
    },
    {
        question: "Quando os produtos estarão disponíveis?",
        answer:
            "Muito em breve! Já estamos iniciamos o desenvolvimento e estamos em fase final de captação de investimentos para expandir a equipe e lançar as primeiras versões."
    }
]

export default function Faq() {
    return (
        <div className="overflow-hidden rounded-lg bg-gray-50">
            <div className="mx-auto max-w-7xl px-6 py-10 sm:py-32 lg:px-8 lg:py-10">
                <div className="flex justify-center items-center">
                    <h2 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                        Perguntas frequêntes
                    </h2>
                </div>
                <div className="flex justify-center items-center">
                    <h4>Tudo que você precisa saber sobre nosso produto</h4>
                </div>
                <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">

                    <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                        {faqs.map((faq) => (
                            <Disclosure key={faq.question} as="div" className="pt-6">
                                <dt>
                                    <DisclosureButton
                                        className="group flex w-full items-start justify-between text-left text-gray-900">
                                        <span className="text-base/7 font-semibold">{faq.question}</span>
                                        <span className="ml-6 flex h-7 items-center">
                      <PlusSmallIcon aria-hidden="true" className="size-6 group-data-[open]:hidden"/>
                      <MinusSmallIcon aria-hidden="true" className="size-6 [.group:not([data-open])_&]:hidden"/>
                    </span>
                                    </DisclosureButton>
                                </dt>
                                <DisclosurePanel transition as="dd" className="mt-2 pr-12 origin-top transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0">
                                    <p className="text-base/7 text-gray-600">{faq.answer}</p>
                                </DisclosurePanel>
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}
