'use client'

import React from "react"
import { useState } from 'react'

import {
    HeartIcon,
    HomeIcon,
    ClipboardDocumentCheckIcon,
} from '@heroicons/react/24/outline'

import Logo from '../../assets/images/HealthTrackerLogoBlackAndWhite.svg'
import FullLogo from '../../assets/images/HealthTrackerFullLogo.svg'


const navigation = [
    { name: 'Visão Geral', href: '#', icon: HomeIcon, current: true },
    { name: 'Exames', href: '#', icon: ClipboardDocumentCheckIcon, current: false },
    { name: 'Biomarcadores', href: '#', icon: HeartIcon, current: false },
    { name: 'Medicamentos', href: '#', icon: HomeIcon, current: false },
    { name: 'Alergias', href: '#', icon: HomeIcon, current: false },
    { name: 'Cirurgias e Vacinas', href: '#', icon: HomeIcon, current: false },
]
const more = [
    { id: 1, name: 'Nutrição', href: '#', icon: HomeIcon, current: false },
    { id: 2, name: 'Suplementação', href: '#', icon: HomeIcon, current: false },
    { id: 3, name: 'Treinamento', href: '#', icon: HomeIcon, current: false },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Sidebar() {
    const [sidebarOpen, setSidebarOpen] = useState(false)

    return (
        <>
            <div>
                {/* Static sidebar for desktop */}
                <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex grow flex-col gap-y-5 overflow-y-auto px-6 pb-4">
                        <div className="flex h-16 shrink-0 items-center">
                            <img
                                alt="HealthTracker"
                                src={Logo}
                                className="h-8 w-auto"
                            />
                            <div className="ml-3 relative">
                                <div className="mr-2">
                                    <p className="text-md font-medium text-gray-700">Pissardo</p>
                                    <p className="text-sm font-medium text-gray-500">foo@bar.com</p>
                                </div>
                            </div>
                        </div>
                        <nav className="flex flex-1 flex-col">
                            <ul role="list" className="flex flex-1 flex-col gap-y-7">
                            <li>
                                    <ul role="list" className="-mx-2 space-y-1">
                                        {navigation.map((item) => (
                                            <li key={item.name}>
                                                <a
                                                    href={item.href}
                                                    className={classNames(
                                                        item.current
                                                            ? 'bg-slate-950 text-white'
                                                            : 'text-slate-700 hover:bg-slate-950 hover:text-white',
                                                        'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                                                    )}
                                                >
                                                    <item.icon
                                                        aria-hidden="true"
                                                        className={classNames(
                                                            item.current ? 'text-white' : 'text-slate-700 group-hover:text-white',
                                                            'h-6 w-6 shrink-0',
                                                        )}
                                                    />
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                                <li>
                                    <ul role="list" className="-mx-2 mt-2 space-y-1">
                                        {more.map((team) => (
                                            <li key={team.name}>
                                                <a
                                                    href={team.href}
                                                    className={classNames(
                                                        team.current
                                                            ? 'bg-slate-950 text-white'
                                                            : 'text-slate-700 hover:bg-slate-950 hover:text-white',
                                                        'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                                                    )}
                                                >
                                                    <team.icon
                                                        aria-hidden="true"
                                                        className={classNames(
                                                            team.current ? 'text-white' : 'text-slate-700 group-hover:text-white',
                                                            'h-6 w-6 shrink-0',
                                                        )}
                                                    />
                                                    <span className="truncate">{team.name}</span>
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                                <li className="mt-auto">
                                    <img
                                        alt="HealthTracker"
                                        src={FullLogo}
                                        className="h-4 w-auto"
                                    />
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="lg:pl-72">
                    <main className="py-10">
                        <div className="px-4 sm:px-6 lg:px-8" id="content"></div>
                    </main>
                </div>
            </div>
        </>
    )
}
